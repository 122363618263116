/**
 * @file It contains scripts for log overview page
 */
import { mapGetters } from "vuex"
import { getListOfStrings } from "@/utils"
import { FORMS } from "@/constants"

export default {
  name: "Logs",
  data() {
    return {
      filters: undefined,
      search : undefined
    }
  },
  methods: {
    /**
      * This method is used custom filtering for vuetify data table.
      * This will be called for every cell value in the table.
      * @param {*} value is content of each cell in a the table
      */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    }
  },
  computed: {
    ...mapGetters({
      logs                      : "logs/logs",
      isLoadingLogs             : "logs/isLoadingLogs",
      translationPreferences    : "translationPreferences/translationPreferences",
      usersIncludingDeletedUsers: "users/usersIncludingDeletedUsers",
      languages                 : "languages/languages"
    }),
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    translationPreferencesMap() {
      const translationPreferencesMap = new Object()
      for (const translationPreference of this.translationPreferences) {
        translationPreferencesMap[translationPreference.id] = translationPreference
      }
      return translationPreferencesMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    logsForTable() {
      const logsForTable = new Array()
      for (const log of this.logs) {
        const user = this.usersMap[log.userId]
        let username
        let disabledButNotDeleted
        if (user) {
          username              = user.name
          disabledButNotDeleted = user.disabledButNotDeleted
        }
        const logForTable = {
          id       : log.id,
          username,
          disabledButNotDeleted,
          entityId : log.entityId,
          entity   : this.$t(this.$LOCALES.ENTITY[log.entityName]),
          createdAt: this.$moment(log.createdAt).format("D MMMM YYYY HH:mm (UTCZ)"),
          level    : this.$t(this.$LOCALES.LOG_LEVEL[log.level])
        }
        let logLocaleId   = this.$LOCALES.LOGS[log.event]
        let parameters    = new Object()
        switch (log.event) {
          case "USER_EMAIL_CHANGE":
          case "CHANNEL_ASK_ORGANISATION_CODE_FOR_WEB_CHANGE":
          case "CHANNEL_OVERRIDE_ASK_ORGANISATION_CODE_FOR_WEB_CHANGE":
          case "CHANNEL_OVERRIDE_TRANSLATION_PREFERENCE_CHANGE":
          case "CHANNEL_ORGANISATION_CODE_CHANGE":
          case "CHANNEL_LINK_CHANGE":
          case "CHANNEL_LINK_DISPLAY_NAME_CHANGE": {
            parameters.name = log.data.otherInformation.name
            parameters.old  = log.data.initialValue || this.$t("372")
            parameters.new  = log.data.finalValue
            break
          }
          case "FIELD_CREATE": {
            parameters.fieldSystemName = log.data.otherInformation.systemName
            break
          }
          case "CHANNEL_TRANSLATION_PREFERENCE_CHANGE": {
            parameters.name = log.data.otherInformation.name
            parameters.old  = this.$t(
              this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[log.data.initialValue]?.name]
            )
            parameters.new  = this.$t(
              this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[log.data.finalValue]?.name]
            )
            break
          }
          case "DOMAIN_NAME_CHANGE":
          case "GROUP_NAME_CHANGE":
          case "CHANNEL_NAME_CHANGE":
          case "CHANNEL_DISPLAY_NAME_CHANGE":
          case "FIELD_LABEL_CHANGE":
          case "FIELD_SYSTEM_NAME_CHANGE":
          case "OPTION_LIST_NAME_CHANGE":
          case "FORM_TEMPLATE_NAME_CHANGE": {
            parameters.old = log.data.initialValue || this.$t("372")
            parameters.new = log.data.finalValue
            break
          }
          case "DOMAIN_ADD_ROLE":
          case "DOMAIN_REMOVE_ROLE": {
            parameters.domainName = log.data.otherInformation.domainName,
            parameters.userOrGroupName = log.data.otherInformation.userName || log.data.otherInformation.groupName
            parameters.roleName = log.data.otherInformation.roleName
            break
          }
          case "ROLE_ADD_ACCESS":
          case "ROLE_REMOVE_ACCESS": {
            parameters.roleName        = log.data.otherInformation.roleName
            parameters.userOrGroupName = log.data.otherInformation.userName || log.data.otherInformation.groupName
            break
          }
          case "CHANNEL_CREATE": {
            parameters.name       = log.data.otherInformation.channelName
            parameters.domainName = log.data.otherInformation.domainName
            break
          }
          case "CHANNEL_ENABLE_FORM":
          case "CHANNEL_DISABLE_FORM": {
            parameters.name     = log.data.otherInformation.channelName
            parameters.formName = log.data.otherInformation.formName
            break
          }
          case "FORM_TEMPLATE_CREATE": {
            parameters.name = log.data.otherInformation.name
            if (log.data.otherInformation.reportForm === false) {
              logLocaleId = this.$LOCALES.LOGS[log.event][FORMS.ISSUE]
            } else {
              logLocaleId = this.$LOCALES.LOGS[log.event][FORMS.REPORT]
            }
            break
          }
          case "CHANNEL_UPDATE_TRANSLATION_CONFIGURATIONS": {
            let oldValue = ""
            let newValue = ""
            for (const configuration of log.data.otherInformation.oldTranslationConfigurations) {
              oldValue += `${oldValue.length ? ", " : ""}${this.$t(configuration.enableTranslationToEnglish ? "578" : "580", {
                language: this.languagesMap[configuration.languageId]?.name
              })}`
            }

            for (const configuration of log.data.otherInformation.newTranslationConfigurations) {
              newValue += `${newValue.length ? ", " : ""}${this.$t(configuration.enableTranslationToEnglish ? "578" : "580", {
                language: this.languagesMap[configuration.languageId]?.name
              })}`
            }
            parameters.channel = log.data.otherInformation.channelName
            parameters.old     = oldValue
            parameters.new     = newValue
            break
          }
          case "CONFIGURATION_UPDATE": {
            switch (log.entityId) {
              case 1: {
                parameters.old = log.data.initialValue
                parameters.new = log.data.finalValue
                break
              }
              case 2: {
                parameters.old = this.$t(
                  this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[+log.data.initialValue]?.name]
                )
                parameters.new = this.$t(
                  this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[+log.data.finalValue]?.name]
                )
                break
              }
              case 7: {
                parameters.old = this.$t(
                  this.$LOCALES.SPEAKUP_ISSUE_ACKNOWLEDGEMENT[log.data.initialValue]
                )
                parameters.new = this.$t(
                  this.$LOCALES.SPEAKUP_ISSUE_ACKNOWLEDGEMENT[log.data.finalValue]
                )
                break
              }
            }
            logLocaleId = this.$LOCALES.LOGS[log.event][log.data.otherInformation.key]
            break
          }
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_ADD":
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_UPDATE":
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_REMOVE": {
            parameters.channelName = log.data.otherInformation.channelName
            parameters.domainName  = log.data.otherInformation.result.domainName
            if (log.data.otherInformation.conditions.length === 1) {
              let fieldValues             = ""
              parameters.formTemplateName = log.data.otherInformation.conditions[0].formTemplateName
              parameters.fieldSystemName  = log.data.otherInformation.conditions[0].fieldSystemName
              for (const fieldValue of log.data.otherInformation.conditions[0].fieldValues) {
                if (fieldValue === log.data.otherInformation.conditions[0].fieldValues.slice(-1)[0]){
                  fieldValues += `${fieldValue}`
                } else {
                  fieldValues += `${fieldValue}${this.$t("1120")}`
                }
              }
              parameters.fieldValues = fieldValues
            }
            break
          }
          case "DATA_RETENTION_RULE_UPDATE_SUMMARY":
          case "DATA_RETENTION_RULE_UPDATE_DESCRIPTION":
          case "DATA_RETENTION_RULE_UPDATE_ISSUE_DOCUMENTS":
          case "DATA_RETENTION_RULE_UPDATE_COMMENTS":
          case "DATA_RETENTION_RULE_UPDATE_MESSAGE_CONTENT":
          case "DATA_RETENTION_RULE_UPDATE_REPORT_DOCUMENTS": {
            parameters.old = log.data.otherInformation.initialValue
            parameters.new = log.data.otherInformation.finalValue
            break
          }
          case "DATA_RETENTION_RULE_UPDATE_FIELD": {
            parameters.name = log.data.otherInformation.systemName
            parameters.old  = log.data.otherInformation.initialValue
            parameters.new  = log.data.otherInformation.finalValue
            break
          }
          default: {
            parameters = log.data.otherInformation
          }
        }
        logForTable.message = this.$t(logLocaleId, parameters)
        logsForTable.push(logForTable)
      }
      return logsForTable
    },
    headersForTable() {
      return this.$TABLES.LOGS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return {
        ...this.$TABLES.LOGS.footer, ...{
          itemsPerPageText: this.$t(this.$TABLES.LOGS.footer.itemsPerPageText)
        }
      }
    },
    itemsForSearch() {
      return getListOfStrings(this.logsForTable, ["id"])
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    }
  }
}
